import React, { useRef, useEffect, useState, FC } from 'react';

type Props = {
    html: TrustedHTML;
    showReadMore?: boolean;
    wordCount?: number;
    removeHeading?: boolean;
}

const TruncatedHtml: FC<Props> = ({ html, showReadMore = true, wordCount = 108, removeHeading = false }) => {
    const [showAll, setShowAll] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);

    let content = html?.toString();
    if (removeHeading) {
        content = content.replace(/<h2>.*?<\/h2>\s*/g, '');
    }
    const truncatedContent = content?.substring(0, wordCount) + '...';

    useEffect(() => {
        if (contentRef.current) {
            const displayContent = !showAll && content?.length > 108 ? truncatedContent : content;
            const toggleText = showAll ? ' read less' : ' read more';

            contentRef.current.innerHTML = `
                <div class="pro-det-text lg:block hidden relative">
                    ${displayContent}
                    ${content?.length > 108 && showReadMore ? `<span class="cursor-pointer font-sofiaMedium font-medium my-1 text-primary  bg-white">${toggleText}</span>` : ''}
                </div>
            `;

            // Add click handler if toggleSpan exists
            const toggleSpan = contentRef.current.querySelector('span');
            if (toggleSpan) {
                toggleSpan.onclick = () => setShowAll(!showAll);
            }
        }
    }, [showAll, content, truncatedContent]);

    return (
        <div ref={contentRef}></div>
    );
}

export default TruncatedHtml;
